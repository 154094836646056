/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { toast } from 'react-toastify';
import TextInput from '../../components/TextField/TextField';
import TextAreaField from '../../components/TextArea/TextArea';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import SelectBox from '../../components/SelectBox/SelectBox';
import styles from './createHotel.module.scss';
import ZipCode from '../../components/ZipCode/ZipCode';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import {
  REACT_APP_API_DESTINATION_DROPDOWN,
  REACT_APP_API_HOTELADMIN_DROPDOWN,
} from '../../redux/apiConstants';
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';

const HotelCreate = React.memo(() => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [destination, setDestination] = useState([]);
  const [hotelAdmin, setHotelAdmin] = useState([]);
  const [submiting, setSubmitting] = useState(false);
  const [formIsReset, setFormIsReset] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Hotel Details');
    const url = REACT_APP_API_DESTINATION_DROPDOWN;
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => setDestination(resp.data.destination.selectdata)); // setting response to state roles
    const url1 = REACT_APP_API_HOTELADMIN_DROPDOWN;
    fetch(url1)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => setHotelAdmin(resp.data.user.selectdata)); // setting response to state roles
  }, []);
  const dispatch = useDispatch();
  const { files } = useContext(TableContext);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    if (!files || files.length === 0) {
      setError('Please upload at least one file.');
      setSubmitting(false);
      return;
    }
    const apiData = {
      destination: data.destination.value,
      hotel: data.hotel,
      hotelAdmin: data.hotelAdmin.value,
      overview: data.overview,
      address: data.address,
      city: data.city,
      country: data.country,
      state: data.state,
      zip: data.zip,
      startingPrice: data.startingPrice,
      active: data.active.value,
      amenities: data.amenities,
      checkin: data.checkin,
      checkout: data.checkout,
      refundPercentage: data.refundPercentage,
      cancellationHour: data.cancellationHour,
      File: files,
    };
    data.actionUrl = 'hotel';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Hotel creation successfull');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }

  const active = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'InActive',
    },
  ];

  const amenities = [
    {
      value: 'Free Wifi',
      label: 'Free Wifi',
    },
    {
      value: 'Ocean view',
      label: 'Ocean view',
    },
    {
      value: '24-hour front desk',
      label: '24-hour front desk',
    },
    {
      value: 'Key card access',
      label: 'Key card access',
    },
    {
      value: 'Balcony',
      label: 'Balcony',
    },
    {
      value: 'Daily housekeeping',
      label: 'Daily housekeeping',
    },
    {
      value: 'Bathtub',
      label: 'Bathtub',
    },
    {
      value: 'Non-smoking rooms',
      label: 'Non-smoking rooms',
    },
    {
      value: 'Safe',
      label: 'Safe',
    },
    {
      value: 'Air conditioning',
      label: 'Air conditioning',
    },
    {
      value: 'City view',
      label: 'City view',
    },
    {
      value: 'Indoor swimming pool',
      label: 'Indoor swimming pool',
    },
    {
      value: 'BBQ facilities',
      label: 'BBQ facilities',
    },
  ];

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="destination"
                    label="Destination"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={destination}
                    errors={errors}
                    placeholder="Choose Destination"
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="hotelAdmin"
                    label="Hotel Admin"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={hotelAdmin}
                    errors={errors}
                    placeholder="Choose Admin"
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextAreaField
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Overview"
                    fieldName="overview"
                    placeHolder="Enter Overview"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Address"
                    fieldName="address"
                    placeHolder="Enter Address"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Hotel"
                    fieldName="hotel"
                    placeHolder="Enter hotel"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    charactersOnly
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="City"
                    fieldName="city"
                    placeHolder="Enter City"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Country"
                    fieldName="country"
                    placeHolder="Enter Country"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="State"
                    fieldName="state"
                    placeHolder="Enter State"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <ZipCode
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Zip"
                    fieldName="zip"
                    placeHolder="Enter Zip"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Starting Price ($)"
                    fieldName="startingPrice"
                    placeHolder="Enter Starting Price"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="active"
                    label="Active"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={active}
                    errors={errors}
                    placeholder="Choose Status"
                    mandatory
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <MultiSelect
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Amenities"
                    name="amenities"
                    values={amenities}
                    control={control}
                    placeHolder="Choose"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Check In"
                    fieldName="checkin"
                    placeHolder="Enter Checkin Time"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Check Out"
                    fieldName="checkout"
                    placeHolder="Enter Checkout Time"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Cancellation Hour"
                    fieldName="cancellationHour"
                    placeHolder="Enter Cancellation Hour"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Refund Percentage"
                    fieldName="refundPercentage"
                    placeHolder="Enter Refund Percentage"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    resetCount={formIsReset}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <MultipleFileUpload />
                </div>
              </div>
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting}
                value={submiting ? 'Please wait...' : 'Submit'}
              />
              <input
                className={styles.resetbtn}
                type="button"
                value="Reset"
                onClick={() => {
                  setFormIsReset(true);
                  reset();
                }}
              />
              {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HotelCreate;
