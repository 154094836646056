import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_APPUSER = `${envValues.REACT_APP_API_ENDPOINT}/appuser?`;
export const REACT_APP_API_FETCH_HOTEL = `${envValues.REACT_APP_API_ENDPOINT}/hotel`;
export const REACT_APP_API_FETCH_DESTINATION = `${envValues.REACT_APP_API_ENDPOINT}/destination?`;
export const REACT_APP_API_FETCH_OFFER = `${envValues.REACT_APP_API_ENDPOINT}/hotel`;
export const REACT_APP_API_FETCH_REVIEW = `${envValues.REACT_APP_API_ENDPOINT}/hotel`;
export const REACT_APP_API_FETCH_INQUIRY = `${envValues.REACT_APP_API_ENDPOINT}/inquiry?`;
export const REACT_APP_API_FETCH_BOOKINGS = `${envValues.REACT_APP_API_ENDPOINT}/booking?active=true&`;
export const REACT_APP_API_FETCH_CANCELLEDBOOKINGS = `${envValues.REACT_APP_API_ENDPOINT}/cancelledbooking?active=false&`;
export const REACT_APP_API_FETCH_ACCOMODATION = `${envValues.REACT_APP_API_ENDPOINT}/hotel`;
export const REACT_APP_API_FETCH_ROOM = `${envValues.REACT_APP_API_ENDPOINT}/room?`;
export const REACT_APP_API_FETCH_CURRENCY = `${envValues.REACT_APP_API_ENDPOINT}/currency?`;
export const REACT_APP_API_FETCH_OFFERVIEW = `${envValues.REACT_APP_API_ENDPOINT}/offer/`;
export const REACT_APP_API_FETCH_PACKAGE = `${envValues.REACT_APP_API_ENDPOINT}/destination`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_DESTINATION_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/destination/select`;
export const REACT_APP_API_CURRENCY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/currency/select`;
export const REACT_APP_API_HOTELADMIN_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/user/select`;
export const REACT_APP_API_ROOM_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/room/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;
export const REACT_APP_API_SETTINGS = `${envValues.REACT_APP_API_ENDPOINT}/settings`;
