/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Entry } from '../../redux/entrySlice';
import SelectBox from '../../components/SelectBox/SelectBox';
import { TableContext } from '../../contexts/tableContext';
import styles from './payment.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { REACT_APP_API_CURRENCY_DROPDOWN } from '../../redux/apiConstants';

const Payment = React.memo(() => {
  const { setDashboardHeader } = useContext(TableContext);
  const [submitting, setSubmitting] = useState(false);
  const { formthemeStyle } = useContext(TableContext);
  const [loading, setLoading] = useState(false);
  let hotelId;
  const {
    settingspaymentSetStyle,
    settingsapiSetStyle,
    settingsgeneralSetStyle,
    settingsprefSetStyle,
  } = useContext(TableContext);
  const [currency, setCurrency] = useState([]);
  const params = useParams();
  const [error, setError] = useState(null);
  const [hotel, setHotel] = useState([]);
  const [curr, setCurr] = useState();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Hotel Details');
    setLoading(true);
    hotelId = params.hotelId;
    const url = REACT_APP_API_CURRENCY_DROPDOWN;
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => setCurrency(resp.data.currency.selectdata)); // setting response to state roles

    const actionData = {};
    actionData.actionUrl = `settings`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      setLoading(false);
      if (Array.isArray(resp.payload) && resp.payload.length > 0) {
        const firstHotel = resp.payload[0];
        console.log(firstHotel, 'first hotel object');
        setCurr(firstHotel.currency.id);
        // Setting the hotel state
        setHotel(firstHotel);

        // Accessing currency details for setting default value in SelectBox
        const defaultCurrencyId = firstHotel.currency.id;
        console.log(defaultCurrencyId); // Check if it logs the correct currency ID

        // ... Rest of your logic
      } else {
        console.error('No hotel data found in the response');
      }
    });
  }, []);

  function onSubmitdata(data) {
    setSubmitting(true);
    const apiData = {
      currency: data.currency.id ? data.currency.id : data.currency.value,
    };
    data.actionUrl = `settings/updatecurrency`;
    data.actionMethod = 'patch';
    data.apiData = apiData;
    console.log(apiData, 'api');
    dispatch(Entry(data)).then((resp) => {
      console.log(resp, 'edit');
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Currency updation successfull');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
        toast.error('Error');
      } else {
        navigate(-1);
      }
    });
  }
  useEffect(() => {
    setDashboardHeader('Settings');
    settingspaymentSetStyle('settingsgeneral');
    settingsprefSetStyle('settingslinks');
    settingsapiSetStyle('settingslinks');
    settingsgeneralSetStyle('settingslinks');
  }, []);
  return (
    <div className={styles[formthemeStyle]} id={styles.addform}>
      {currency.length > 0 && curr && (
        <div>
          <SelectBox
            className={styles.inputbox}
            classNamedark={styles.inputbox1}
            name="currency"
            label="Choose default currency"
            labelstyle={styles.formlabel}
            control={control}
            register={register}
            values={currency}
            errors={errors}
            placeholder="Choose default currency"
            defaultvalue={curr}
            mandatory
          />
        </div>
      )}
      <div className={styles.savebtnsection}>
        <input
          className={styles.savebtn}
          type="submit"
          disabled={submitting}
          value={submitting ? 'Please wait...' : 'Submit'}
          onClick={handleSubmit(onSubmitdata)}
        />
      </div>
    </div>
  );
});

export default Payment;
