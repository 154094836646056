// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateOfBirthPicker_inputbox__2L8vu {\n  padding: 5px !important;\n  color: transparent;\n}\n\n.DateOfBirthPicker_react-datepicker__input-container__z\\+YlH input::placeholder {\n  color: transparent;\n}\n\n.DateOfBirthPicker_react-datepicker__input-container__z\\+YlH input:focus {\n  outline: none; /* Remove default focus outline */\n}\n\n/* Example with increased specificity */\ndiv.DateOfBirthPicker_react-date-picker__inputGroup__input__3y\\+j9:invalid {\n  color: transparent; /* or any other color you prefer */\n}", "",{"version":3,"sources":["webpack://./src/components/DateOfBirthPicker/DateOfBirthPicker.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACE;EACE,aAAA,EAAA,iCAAA;AAEJ;;AACA,uCAAA;AACA;EACI,kBAAA,EAAA,kCAAA;AAEJ","sourcesContent":[".inputbox{\n    padding: 5px !important;\n    color: transparent;\n}\n\n.react-datepicker__input-container input::placeholder {\n    color: transparent;\n  }\n  .react-datepicker__input-container input:focus {\n    outline: none; /* Remove default focus outline */\n  }\n\n/* Example with increased specificity */\ndiv.react-date-picker__inputGroup__input:invalid {\n    color: transparent; /* or any other color you prefer */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputbox": "DateOfBirthPicker_inputbox__2L8vu",
	"react-datepicker__input-container": "DateOfBirthPicker_react-datepicker__input-container__z+YlH",
	"react-date-picker__inputGroup__input": "DateOfBirthPicker_react-date-picker__inputGroup__input__3y+j9"
};
export default ___CSS_LOADER_EXPORT___;
